<script>
	import Modal from "./Modal.svelte";
	import Upload from "./Upload.svelte";

	const websites = [
		{
			link: "https://loud.red/",
			name: "loud.red",
			description: "Ephemeral file sharing",
			image: "loudred",
		},
		{
			link: "https://mofi.loud.red/",
			name: "Mofi",
			description: "Content-aware fill and trim for music",
			image: "mofi",
		},
		{
			link: "https://set.loud.red/",
			name: "Setacular",
			description: "Multiplayer puzzle game",
			image: "setacular",
		},
		{
			link: "https://projects.loud.red/gradable/",
			name: "Gradable",
			description: "Speedy grading of CS 329E assignments",
			image: "gradable",
		},
		{
			link: "https://projects.loud.red/wrangler/",
			name: "Wrangler",
			description: "Quick text manipulation",
			image: "microsites",
		},
	];

	let isOpen = false;

	function openFileUpload(e) {
		isOpen = true;
		e.preventDefault();
	}

	function closeFileUpload() {
		isOpen = false;
	}
</script>

<header>
	<h1>loud.red</h1>
	<p>This domain hosts various services, here are a couple of them:</p>
</header>

<nav>
	{#each websites as { link, name, description, image }, i}
		<a
			href={link}
			on:click={i === 0 ? openFileUpload : undefined}
			title={description}
		>
			<div class="image">
				<img
					src="/icons/{image}.png"
					alt="Icon for {name}"
					width="100"
					height="100"
				/>
				<div class="fade" />
			</div>
			<h2>{name}</h2>
		</a>
	{/each}
</nav>

{#if isOpen}
	<Modal on:click={closeFileUpload}>
		<div class="close" on:click={closeFileUpload}>&times;</div>
		<Upload />
	</Modal>
{/if}

<footer>&copy; 2016&ndash;2023</footer>

<style>
	h1 {
		font-size: 1.2em;
	}

	nav {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
	}

	nav a {
		display: flex;
		flex-direction: column;
		text-decoration: none;
	}

	nav a:not(:first-child) {
		margin-left: 1em;
	}

	nav .image {
		display: flex;
		border-radius: 20px;
		overflow: hidden;
		position: relative;
		font-size: 0;
	}

	nav .image .fade {
		border-radius: 20px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.2) 100%
		);
		box-shadow: inset 0 1px 0 1px rgba(255, 255, 255, 0.2),
			inset 0 0 0 1px rgba(0, 0, 0, 0.2);
	}

	nav h2 {
		font-size: 1em;
		font-weight: normal;
	}

	footer {
		opacity: 0.5;
		text-align: right;
	}

	.close {
		display: block;
		float: right;
		cursor: pointer;
		padding: 0 0 1em 1em;
	}
</style>
