<script>
	import UploadForm from "./UploadForm.svelte";
	import UploadItem from "./UploadItem.svelte";

	let uploads = [];
	let circumvent = false;
	$: prefix = circumvent
		? "https://s3.amazonaws.com/up.loud.red/"
		: "https://loud.red/";

	function handleFile(e) {
		uploads = [...uploads, e.detail.file];
	}
</script>

Files are deleted after a week. Don't upload anything illegal.

<UploadForm on:select={handleFile} />
<label
	for="circumvent"
	title="Using an alternate host can circumvent monitoring or restrictions on your network."
>
	<div class="checkbox" class:active={circumvent} />
	<input type="checkbox" id="circumvent" bind:checked={circumvent} />
	<div>Use alternate host</div>
</label>

{#if uploads.length > 0}
	<div id="uploads">
		{#each uploads as file}
			<UploadItem {file} {prefix} proxied={!circumvent} />
		{/each}
	</div>
{/if}

<style>
	label {
		margin-top: 1em;
		display: flex;
		align-items: center;
	}

	#uploads {
		margin-top: 1em;
	}

	#circumvent {
		visibility: hidden;
		position: absolute;
	}

	.checkbox {
		font-size: 0.8em;
		background: white;
		height: 1em;
		width: 1em;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		cursor: pointer;
		position: relative;
		margin-right: 0.5em;
	}

	.checkbox.active {
		background: #f5626c;
	}

	.checkbox:before,
	.checkbox:after {
		position: absolute;
		content: "";
		display: block;
		top: 65%;
		left: 30%;
		height: 0.8em;
		width: 0.16em;
		border-radius: 9em;
		background: white;
		transform: rotateZ(220deg);
		transform-origin: center 0.08em;
	}

	.checkbox:after {
		height: 0.4em;
		transform: rotateZ(-220deg);
	}
</style>
