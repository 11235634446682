<script>
	import { fade, fly } from "svelte/transition";
</script>

<div
	class="shade"
	transition:fade={{ duration: 200 }}
	aria-hidden="true"
	on:click
/>

<div class="modal" transition:fly={{ duration: 200, y: 20 }}>
	<slot />
</div>

<style>
	.shade {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.6);
		z-index: 10;
	}

	.modal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #e8e9eb;
		padding: 1em;
		border: 1px solid rgba(0, 0, 0, 0.6);
		border-radius: 0.5em;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
		z-index: 10;
		max-width: calc(380px - 2 * (1em + 10px));
	}
</style>
