<script>
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	function handlePaste(e) {
		if (e.clipboardData.items.length > 0) {
			e.preventDefault();
			handleFiles(e.clipboardData.items);
		}
	}

	function handleDrop(e) {
		e.preventDefault();
		if (e.dataTransfer.items.length > 0) {
			handleFiles(e.dataTransfer.items);
		}
	}

	function handleSelect(e) {
		[...e.target.files].forEach(bringBack);
	}

	function handleFiles(files) {
		[...files]
			.filter((item) => item.kind == "file" || item.type == "text/plain")
			.forEach((item) => {
				if (item.kind == "file") {
					bringBack(item.getAsFile());
				} else {
					item.getAsString((text) => {
						bringBack(
							new File([text], "plain.txt", {
								type: "text/plain",
							})
						);
					});
				}
			});
	}

	function bringBack(file) {
		if (file) {
			dispatch("select", {
				file: file,
			});
		}
	}
</script>

<svelte:window
	on:paste={handlePaste}
	on:drop={handleDrop}
	on:dragover={handleDrop}
/>

<div id="drop" on:click={() => document.getElementById("selector").click()}>
	Drop, paste, or click to upload a file.
</div>

<input id="selector" type="file" multiple on:change={handleSelect} />

<style>
	#drop {
		border: 2px dashed;
		padding: 1em;
		cursor: pointer;
		margin-top: 1em;
	}

	#selector {
		display: none;
	}
</style>
