<script>
	export let file, prefix, proxied;

	let state = "waiting"; // one of [waiting, uploading, complete]
	let progress = 0;
	let key = "";
	$: url = proxied ? prefix + key.split("/")[0] : prefix + key;

	fetch("https://366f46x4fc.execute-api.us-east-1.amazonaws.com/dev/upload", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			name: unescape(file.name),
			type: file.type,
		}),
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.success) return data;
			else throw data.message;
		})
		.then(startUpload);

	function startUpload(params) {
		state = "uploading";
		key = params.key;

		const request = new XMLHttpRequest();
		request.upload.addEventListener("progress", (e) => {
			if (e.lengthComputable) progress = (e.loaded / e.total) * 100;
		});
		request.upload.addEventListener("load", () => {
			state = "complete";
		});
		request.open("PUT", params.url);
		request.overrideMimeType(file.type);
		request.send(file);
	}

	function selectAndCopy() {
		this.select();
		const range = document.createRange();
		range.selectNodeContents(this);
		const selection = window.getSelection();
		selection.removeAllRanges();
		selection.addRange(range);
		this.setSelectionRange(0, 99999);
		document.execCommand("copy");
	}
</script>

<div class="item">
	<div class="top">
		<div class="name">
			{#if state == "complete"}
				<a href={url} target="_blank">{file.name}</a>
			{:else}{file.name}{/if}
		</div>
		{#if state == "uploading"}
			<div class="percentage">{progress.toFixed(2)}%</div>
		{/if}
	</div>

	{#if state == "waiting"}
		<div class="status">Waiting...</div>
	{:else if state == "uploading"}
		<div class="progress">
			<div style="width:{progress}%" />
		</div>
	{:else if state == "complete"}
		<input type="text" readonly value={url} on:click={selectAndCopy} />
	{/if}
</div>

<style>
	.item {
		background: white;
		padding: 0.5em;
		border: 1px solid rgba(0, 0, 0, 0.2);
		margin-bottom: -1px;
	}

	.item:first-of-type {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	.item:last-of-type {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	.top {
		display: flex;
		margin-bottom: 0.5em;
	}

	.name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}

	.percentage {
		margin-left: 0.5em;
		font-variant-numeric: tabular-nums lining-nums;
	}

	.status,
	.progress,
	input {
		display: block;
		width: 100%;
		padding: 0;
		border: 0;
		margin: 0;
		background: none;
		line-height: 1;
		outline: none;
	}

	input {
		padding: 0.5em;
		margin: -0.5em;
		box-sizing: initial;
		height: 1em;
	}

	.progress div {
		height: 1em;
		min-width: 1px;
		background: #f5626c;
		transition: width 0.2s linear;
	}
</style>
